import React from "react";
import { navigate } from "gatsby";
import * as styles from "./parts.module.scss";

interface Props {
  path: string;
  state?: any;
}

export const SmartBackButton: React.FC<Props> = ({ path, state }) => {
  return (
    <div className={styles.backButton}>
      <button
        onClick={(e) => {
          e.stopPropagation();
          navigate(path, { state });
        }}
      >
        <div>
          <img src="/back.svg" />
          <div>Retour</div>
        </div>
      </button>
    </div>
  );
};
