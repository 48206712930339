import React from "react";
import * as material from "material-colors";
import * as styles from "./colorPicker.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";

interface Props {
  setColor: any;
}
export const ColorPicker: React.FC<Props> = ({ setColor }) => {
  return (
    <>
      <div style={{ width: "100vw" }}>
        <Swiper
          slidesPerView={7.4}
          // direction="horizontal"
          //   spaceBetween={14}
          // onSwiper={(swiper) => setSwiper(swiper)}
          // onSlideChange={(e) => setSlide(e.activeIndex)}
          //   centeredSlides={true}
          // breakpoints={{
          //   320: {
          //     slidesPerView: 1.6,
          //     spaceBetween: 26,
          //   },
          //   560: {
          //     slidesPerView: 3,
          //     spaceBetween: 20,
          //   },
          // }}
          // centeredSlidesBounds={true}
        >
          {Colors.map((color: any, index: number) => (
            <SwiperSlide key={`color-${index}`}>
              <div
                className={`${styles.color}`}
                style={{ backgroundColor: `${color}` }}
                onClick={() => setColor(color)}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
};

const Colors = [
  material.red["500"],
  material.pink["500"],
  material.purple["500"],
  material.deepPurple["500"],
  material.indigo["500"],
  material.blue["500"],
  material.lightBlue["500"],
  material.cyan["500"],
  material.teal["500"],
  material.green["500"],
  material.lightGreen["500"],
  material.lime["500"],
  material.yellow["500"],
  material.amber["500"],
  material.orange["500"],
  material.deepOrange["500"],
  material.brown["500"],
  material.blueGrey["500"],
];
