import { graphql, navigate } from "gatsby";
import React, { useEffect, useState } from "react";
import { LayoutApp } from "../components/Layout/layoutApp";
import SEO from "../components/seo";
import * as styles from "../styles/app.module.scss";
import * as parts from "../components/Parts/parts.module.scss";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { CirclePicker } from "react-color";
import { ColorPicker } from "../components/ColorPicker/colorPicker";
import { useWindowSize } from "../components/Utils/useWindowSize";
import { SmartBackButton } from "../components/Parts/smartBackButton";
import { usePageContext } from "../components/Context/pageContext";

export interface Location {
  location: any;
}

const ES = "https://tours-ecard.soixanteseize.tech/";

const Message: React.FC<Location & any> = ({ location, data: { message } }) => {
  const { lang } = usePageContext();
  const [text, setText] = useState<any>(
    location.state?.text ? location.state.text : ""
  );
  const [name, setName] = useState<any>(
    location.state?.name ? location.state?.name : ""
  );
  const [color, setColor] = useState<string>(
    location.state?.color ? location.state.color : "#222222"
  );
  const dimensions = useWindowSize();

  useEffect(() => {
    if (!location.state) navigate("/");
  }, []);

  const buildVideo = () => {
    // const result = {
    //   mp4Url:
    //     "https://res.cloudinary.com/soixanteseize/video/upload/w_1080,h_1080/w_1080,h_1080,l_generated:aly28ivwokfupd4epsy7,fl_splice,du_3/fl_layer_apply/v1621621259/posters/8fc7026ca4a0.mp4",
    //     shareUrl: 'https://res.cloudinary.com/soixanteseize/video/upload/w_1080,h_1080/w_1080,h_1080,l_generated:aly28ivwokfupd4epsy7,fl_splice,du_3/fl_layer_apply/v1621621259/posters/8fc7026ca4a0.mp4'
    // };
    const urlParams =
      `${ES}?` +
      new URLSearchParams({
        color,
        author: name,
        message: text,
        poster: location.state.poster,
      });
    fetch(urlParams, {
      method: "get",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) =>
        res.json().then((result) => {
          navigate("/send", {
            state: {
              res: result.data,
              text,
              name,
              color,
              poster: location.state.poster,
              slide: location.state.slide,
            },
          });
        })
      )
      .catch((err) => {
        console.log(`error: ${err}`);
      });
  };

  return (
    <div>
      <LayoutApp title={message.content[lang]} step={2}>
        <SEO
          title="Message"
          links={[
            {
              rel: "preconnect",
              href: `https://res.cloudinary.com`,
            },
            {
              rel: "dns-prefetch",
              href: `https://res.cloudinary.com`,
            },
          ]}
        />
        <div className={styles.textColorWrapper}>
          <div className={styles.formWrapper}>
            <form style={{ marginBottom: 0 }} onSubmit={() => 3}>
              <div className={styles.textarea}>
                <textarea
                  aria-label="message"
                  id="message"
                  name="message"
                  required={true}
                  autoComplete="off"
                  maxLength={170}
                  placeholder="Votre texte"
                  value={text}
                  onChange={(e) => setText(e.target.value.replace(/\n/g, ""))}
                  style={{ color }}
                />
                {text.length > 0 && (
                  <div className={styles.clearWrapper}>
                    <span onClick={() => setText("")}>
                      <AiOutlineCloseCircle />
                    </span>
                  </div>
                )}
              </div>
              <div className={styles.input}>
                <input
                  type="text"
                  name="name"
                  required={true}
                  aria-label="name"
                  maxLength={14}
                  placeholder="Votre nom"
                  autoComplete="off"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  style={{ color }}
                />
                {name.length > 0 && (
                  <div
                    className={styles.clearWrapper}
                    onClick={() => setName("")}
                  >
                    <AiOutlineCloseCircle />
                    {/* </span> */}
                  </div>
                )}
              </div>
            </form>
          </div>
          {/* <SketchPicker /> */}
          {dimensions.width >= 568 &&
          dimensions.width / dimensions.height > 1.3345 ? (
            <div style={{ width: "26%" }}>
              <CirclePicker
                width="100%"
                circleSize={dimensions.width / 46.54}
                circleSpacing={dimensions.width / 46.54 / 2}
                onChange={(color: any) => setColor(color.hex)}
              />
            </div>
          ) : (
            <div className={styles.pickerSwiper}>
              <ColorPicker setColor={setColor} />
            </div>
          )}
        </div>
        <div className={styles.buttonsWrapper}>
          <SmartBackButton
            path="/visual/"
            state={{ slide: location.state?.slide }}
          />
          <div
            className={`${parts.button} ${!(text && name) && parts.greyedOut}`}
          >
            <button onClick={text && name ? buildVideo : () => {}}>
              {message.button[lang]}
            </button>
          </div>
        </div>
      </LayoutApp>
    </div>
  );
};

export const query = graphql`
  query message {
    message: sanityApp {
      button: messageButton {
        en
        fr
      }
      content: messageContent {
        en
        fr
      }
    }
  }
`;

export default Message;
